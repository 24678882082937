import Jax from '../../components/jax/jax';
import LiveEvent from '../../components/live/live';

class Cart {
  constructor() {
    this.options = {
      cartAdd: '[data-to-cart]',
      cartDec: '[data-cart-dec]',
      cartInc: '[data-cart-inc]',
      cartRemove: '[data-cart-remove]',
      cartBlock: '[data-cart-block]',
      cartBlockDataset: 'cartBlock',
      orderForm: '[data-order-form]',
      orderFormWatchField: '[data-cart-watch-field]',
      loadingClass: 'loading',
    };
    this.routes = {
      index: '/cart',
      add: '/cart/add',
      remove: '/cart/remove',
      inc: '/cart/inc',
      dec: '/cart/dec',
      quantity: '/cart/quantity',
    };
  }

  init() {
    this.bind();
    return this;
  }

  bind() {
    new LiveEvent('click', this.options.cartAdd, (e) => {
      e.preventDefault();
      const button = e.target.closest(this.options.cartAdd);
      const countInputSelector = button.dataset.countInput;
      let count = 1;
      if (countInputSelector) {
        const countInput = document.querySelector(countInputSelector);
        if (countInput) {
          count = parseInt(countInput.value, 10);
        }
      }

      this.add(button.dataset.id, count);
    });

    new LiveEvent('click', this.options.cartDec, (e) => {
      e.preventDefault();
      const button = e.target;
      this.dec(button.dataset.cartDec);
    });

    new LiveEvent('click', this.options.cartInc, (e) => {
      e.preventDefault();
      const button = e.target;
      this.inc(button.dataset.cartInc);
    });

    new LiveEvent('click', this.options.cartRemove, (e) => {
      e.preventDefault();
      const button = e.target.closest(this.options.cartRemove);
      this.remove(button.dataset.cartRemove);
    });

    new LiveEvent('change', this.options.orderFormWatchField, (e) => {
      e.preventDefault();
      this.update();
    });
  }

  add(id, quantity) {
    this.request(this.routes.add, {
      id,
      quantity,
    });
  }

  dec(position) {
    this.request(this.routes.dec, {
      position,
    });
  }

  inc(position) {
    this.request(this.routes.inc, {
      position,
    });
  }

  remove(position) {
    this.request(this.routes.remove, {
      position,
    });
  }

  request(route, formData) {
    const request = new Jax(route, 'POST', true);
    request.send(formData)
      .then((data) => {
        if (data.status && data.message) {
          this.message(data.status, data.message);
        }
        this.update();
      });
  }

  /**
     * @param status "success"|"error"
     * @param message
     */
  message(status, message) {
    const successBlock = document.querySelector('[data-cart-success]');
    if (!successBlock) {
      return;
    }
    const messageBlock = successBlock.querySelector('[data-message]');
    if (messageBlock) {
      messageBlock.innerHTML = message;
    }

    successBlock.classList.add('_show');

    const successTimer = setTimeout(() => {
      successBlock.classList.remove('_show');
    }, 4000);

    successBlock.querySelector('[data-close-success]').addEventListener('click', () => {
      clearTimeout(successTimer);
      successBlock.classList.remove('_show');
    });
  }

  update() {
    const blocks = document.querySelectorAll(this.options.cartBlock);
    if (blocks.length > 0) {
      blocks.forEach((block) => {
        block.classList.add(this.options.loadingClass);
      });
    }
    this.updateRequest();
  }

  updateRequest() {
    const form = document.querySelector(this.options.orderForm);
    let sendPromise;
    if (form) {
      const updateRequest = new Jax(null, 'POST');
      sendPromise = updateRequest.send(new FormData(form));
    } else {
      const updateRequest = new Jax();
      sendPromise = updateRequest.send();
    }

    sendPromise
      .then((page) => {
        const pageElement = document.createElement('div');
        pageElement.innerHTML = page;
        this.updateBlocks(pageElement);
      });
  }

  updateBlocks(page) {
    page.querySelectorAll(this.options.cartBlock).forEach((newBlock) => {
      const name = newBlock.dataset.cartBlock;
      const block = document.querySelector(`[data-cart-block="${name}"]`);
      block.classList.remove(this.options.loadingClass);
      block.innerHTML = newBlock.innerHTML;
    });
    let event = new Event('DOMContentMutated');
    document.dispatchEvent(event);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const cart = new Cart();
  cart.init();
});