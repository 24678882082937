function setActiveOffer(offerId) {
  const currentOffer = document.querySelector(`[data-offer="${offerId}"]`);
  document.querySelector('[data-price-verbose]').innerHTML = currentOffer.dataset.price;
  document.querySelector('[data-to-cart]').dataset.id = offerId;
}

if (document.querySelector('[data-to-cart-block]')) {
  document.querySelectorAll('[data-offer]').forEach((el, key) => {
    el.addEventListener('change', () => {
      setActiveOffer(el.dataset.offer);
    });
    if (key === 0) {
      setActiveOffer(el.dataset.offer);
    }
  });
}