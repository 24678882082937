function initMapYandex() {
  document.querySelectorAll('[data-map]').forEach((mapElement) => {
    const myMap = new ymaps.Map(mapElement, {
      center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
      zoom: mapElement.dataset.zoom,
      controls: ['zoomControl', 'fullscreenControl'],
    }, {
      searchControlProvider: 'yandex#search',
    });

    const collection = new ymaps.GeoObjectCollection(null, {
      // Запретим появление балуна.
      hasBalloon: false,
    });

    const offices = document.querySelectorAll('[data-office]');

    offices.forEach((office) => {
      collection.add(new ymaps.Placemark([office.dataset.lat, office.dataset.lng], {}, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: mapElement.dataset.mark,
        // Размеры метки.
        iconImageSize: [25, 34],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-12.5, -34],
      }));

      office.addEventListener('click', () => {
        offices.forEach((el) => {
          el.classList.remove('_active');
        });
        office.classList.add('_active');
        myMap.panTo([parseFloat(office.dataset.lat), parseFloat(office.dataset.lng)], {
          useMapMargin: true,
        });
      });
    });

    myMap.geoObjects.add(collection);

    myMap.behaviors.disable('scrollZoom');
    if (window.innerWidth <= 1024) {
      myMap.behaviors.disable('drag');
    }
  });
}

window.addEventListener('load', () => {
  if (window.ymaps) {
    ymaps.ready(initMapYandex);
  }
});