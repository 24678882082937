import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

function bindArrows(left, right, slider) {
  const leftArrow = document.querySelector(left);
  const rightArrow = document.querySelector(right);

  if (leftArrow) {
    leftArrow.addEventListener('click', () => {
      slider.goTo('prev');
    });
  }

  if (rightArrow) {
    rightArrow.addEventListener('click', () => {
      slider.goTo('next');
    });
  }
}

if (document.querySelector('[data-product-slider]')) {
  const slider = tns({
    container: '[data-product-slider]',
    items: 1,
    slideBy: 1,
    nav: true,
    speed: 700,
    controlsContainer: '[data-customize-controls]',
    navContainer: '[data-customize-controls]',
    navAsThumbnails: true,
    controls: false,
    loop: true,
    swipeAngle: true,
    rewind: false,
    autoplayButtonOutput: false,
    mode: 'gallery',
    animateIn: 'fadeInDown',
    animateOut: 'fadeOutDown',
  });

  bindArrows('[data-offer-left]', '[data-offer-right]', slider);
}

if (document.querySelector('[data-index-slider]')) {
  const slider = tns({
    container: '[data-index-slider]',
    items: 1,
    slideBy: 1,
    nav: true,
    speed: 700,
    controls: false,
    loop: true,
    mode: 'gallery',
    animateIn: 'fadeInDown',
    animateOut: 'fadeOutDown',
    swipeAngle: false,
    rewind: false,
  });

  bindArrows('[data-index-left]', '[data-index-right]', slider);
}

if (document.querySelector('[data-accessories-slider]')) {
  const slider = tns({
    container: '[data-accessories-slider]',
    slideBy: 1,
    nav: true,
    speed: 700,
    controls: false,
    loop: true,
    swipeAngle: false,
    rewind: false,
    responsive: {
      320: {
        items: 2,
        disabled: false,
      },
      768: {
        items: 3,
      },
      1280: {
        items: 4,
        disabled: true,
      },
    },
  });

  bindArrows('[data-accessories-left]', '[data-accessories-right]', slider);
}