/**
 * Удобная штуковина для форм
*/

class Depender {
  bind() {
    document.querySelectorAll('input[type=radio], select').forEach((input) => {
      if (!input.dataset.initializedDepender) {
        return;
      }
      input.dataset.initializedDepender = true;
      input.addEventListener('change', (e) => {
        this.listener(input, e);
      });
    });
    document.querySelectorAll('input[type=radio]:checked, select').forEach((input) => {
      this.listener(input);
    });
  }

  listener(input) {
    document.querySelectorAll(`[data-depend-field="${input.name}"]`).forEach((block) => {
      this.processBlock(block, input.value);
    });
  }

  processBlock(block, value) {
    const rawValues = block.dataset.dependValues ? block.dataset.dependValues : '[]';
    const values = JSON.parse(rawValues);
    let has = false;
    values.forEach((val) => {
      if (val == value) {
        has = true;
      }
    });
    block.dataset.dependVisible = has ? 'true' : 'false';
  }
}

const dep = new Depender();

document.addEventListener('DOMContentMutated', ()=>{
  dep.bind();
});

document.addEventListener('DOMContentLoaded', ()=>{
  dep.bind();
});