import LiveEvent from '../../components/live/live';

function get(updown) {
  return parseInt(updown.querySelector('[data-updown-input]').value, 10);
}

function set(updown, value) {
  if (value < 1) {
    value = 1;
  }
  updown.querySelector('[data-updown-input]').value = value;
}

function inc(updown, on) {
  const val = get(updown);
  set(updown, val + on);
}

new LiveEvent('click', '[data-updown] [data-updown-minus]', function minusValue() {
  inc(this.closest('[data-updown]'), -1);
});

new LiveEvent('click', '[data-updown] [data-updown-plus]', function plusValue() {
  inc(this.closest('[data-updown]'), 1);
});

new LiveEvent('blur', '[data-updown] [data-updown-input]', function blur() {
  const updown = this.closest('[data-updown]');
  set(updown, get(this.closest('[data-updown]')));
});