require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('./common/true-vh.js');
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');
require('./hamburger');
require('./common/floating-field');
require('./common/need-animation.js');
require('./common/depend.js');

require('./cart/cart.js');
require('./cart/updown.js');
require('./cart/offices.js');

require('./product/index.js');

require('./field/phone.js');
require('../components/smart-tabs/smart-tabs');
require('../vue/app.js');
require('./sliders');